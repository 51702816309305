<div class="d-flex flex-column w-100 h-100 confirm-dialog">
  <div class="title" *ngIf="data?.title">{{data.title}}<hr></div>
  <div class="message">{{data?.message?.name ? data.message() : data.message }}</div>
  <div class="footnote" *ngIf="data?.footnote">{{data.footnote}}</div>
  <div class="actions d-flex flex-grow-1 justify-content-end w-100 align-items-end mt-2 gap-2">
    <button *ngIf="data?.showCancelBtn" class="ra-blue-btn mat-raised-button" (click)="onCancel()">{{data?.cancelButtonLabel || 'Cancel'}}</button>
    <button *ngIf="data?.showSaveBtn" class="ra-blue-btn mat-raised-button" (click)="onSave()">{{data?.saveButtonLabel || 'Save'}}</button>
    <button class="ra-blue-btn mat-raised-button" (click)="onOk()">{{data?.okButtonLabel || 'Ok'}}</button>
  </div>
</div>

