import { apiConfig } from './../../services/api-config';
import { Subject } from 'rxjs';
import { PartnerCustomer, PartnerCustomersInfo } from './../../models/partner-customers-info';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UiConfig } from 'json-dynamic-form';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerCustomerService {

  private partnerCustomers: PartnerCustomer[];
  private partnerCustomersInfo: PartnerCustomersInfo[] = [];
  selectedPartnerCustomer: PartnerCustomer;
  private partnerCustomersChanged = new Subject<PartnerCustomer[]>();
  partnerCustomersChanged$ = this.partnerCustomersChanged.asObservable();
  featureUIConfig: any;
  publishUIConfig: any;

  constructor(
    private http: HttpClient
  ) { }

  getPartnerCustomersInfo(forceFetch: boolean = false): Observable<PartnerCustomer[]> {
    return (this.partnerCustomers && !forceFetch) ? of(this.partnerCustomers) :
      this.http.get<any>(`${apiConfig.getPartnersCustomersInfo}`).
        pipe(map(x => {
          const partnerCustomersInfo = x.partners_customers_info || [];
          this.partnerCustomersInfo = partnerCustomersInfo;
          const partnerCustomers: PartnerCustomer[] = [];
          partnerCustomersInfo.forEach(partnerCustomerInfo => {
            partnerCustomerInfo.customers.forEach(customer => {
              partnerCustomers.push({
                partner: partnerCustomerInfo.partner,
                customer: customer.customer,
                description: `${partnerCustomerInfo?.partner?.substring(3)}-${customer.customer}`
              });
            });
          });
          this.setPartnerCustomers(partnerCustomers);
          if (forceFetch) {
            this.emitPartnerCustomerChanged(this.partnerCustomers);
            if (this.selectedPartnerCustomer) {
              const selectedPartnerCustomer = this.partnerCustomers?.find(
                partnerCust => partnerCust.customer === this.selectedPartnerCustomer.customer && partnerCust.partner === this.selectedPartnerCustomer.partner
              );
              this.setSelectedPartnerCustomer(selectedPartnerCustomer);
            } 
          }
          return this.partnerCustomers;
        }));
  }

  changeSelectedPartnerCustomer(partnerCustomers: PartnerCustomer[]) {
    this.setSelectedPartnerCustomer(partnerCustomers?.[0] || {
      partner: '',
      customer: '',
      description: ''
    });
  }

  emitPartnerCustomerChanged(partnerCustomers: PartnerCustomer[]) {
    this.partnerCustomersChanged.next(partnerCustomers);
  }

  getPartnerCustomers() {
    return this.partnerCustomers;
  }

  setPartnerCustomers(partnerCustomers: PartnerCustomer[]) {
    this.partnerCustomers = partnerCustomers;
  }

  setSelectedPartnerCustomer(selectedPartnerCustomer: PartnerCustomer) {
    this.selectedPartnerCustomer = selectedPartnerCustomer;
  }

  getSelectedPartnerCustomer() {
    return this.selectedPartnerCustomer || {
      partner: '',
      customer: '',
      description: ''
    };
  }

  getPartnerCustomersMap() {
    return this.partnerCustomersInfo;
  }

  getPartnerName(partnerName: string) {
    return partnerName ? partnerName.replace('RA-', '') : partnerName;
  }

  addCustomer(partner: string, customer: string, task: string, toastr: ToastrService) {
    toastr.info(`Creating new customer ${customer} for partner ${partner}!`)
    return this.http.post(`${apiConfig.createCustomer}/${this.getPartnerName(partner)}/customers/${customer}`, {
      task: task
    });
  }

  updateCustomer(partner: string, customer: string, newCustomer: string, toastr: ToastrService) {
    toastr.info(`Customer update is in progress!`)
    return this.http.put(`${apiConfig.createCustomer}/${this.getPartnerName(partner)}/customers/${customer}`, {
      new_customer: newCustomer
    });
  }

  deleteCustomer(partner: string, customer: string) {
    return this.http.delete(`${apiConfig.deleteCustomer}/${this.getPartnerName(partner)}/customers/${customer}`, {});
  }

  updatePartner(partner: string, newPartnerName: any, feature: any) {
    return this.http.put(`${apiConfig.createPartner}/${this.getPartnerName(partner)}`, {
      new_partner: partner === newPartnerName ? '' : newPartnerName,
      feature: feature
    });
  }

  addPartner(partner: string, feature: any) {
    return this.http.post(`${apiConfig.createPartner}/${this.getPartnerName(partner)}`, {
      feature: feature
    });
  }

  updateFeature(partner: string, customer: string, feature: any) {
    return this.http.put(
      `${apiConfig.createUpdateFeature}/${this.getPartnerName(partner)}/customers/${customer}/feature_parameter`,
      feature
    );
  }

  getFeatureUIConfig(): Observable<UiConfig> {
    return this.featureUIConfig ?
      of(cloneDeep(this.featureUIConfig)) : this.http.get(environment?.env === 'local' ? './assets/config/feature_ui_control_settings.json' : `${apiConfig.getJson}?path=config/feature_ui_control_settings.json`).pipe(tap((data: UiConfig) => {
        this.featureUIConfig = cloneDeep(data);
      }));
  }

  getFeatureValue(path: string) {
    return this.http.get(`${apiConfig.getJson}?path=${path}`);
  }

  getCurrentUpdateStatus(task_id: string) {
    return this.http.get(`${apiConfig.getCurrentUpdateStatus}?id=${task_id}`);
  }

  getPublishUIConfig(): Observable<UiConfig> {
    return this.publishUIConfig ?
      of(cloneDeep(this.publishUIConfig)) : this.http.get(environment?.env === 'local' ? './assets/config/publish_ui_control_settings.json' : `${apiConfig.getJson}?path=config/publish_ui_control_settings.json`).pipe(tap((data: UiConfig) => {
        this.publishUIConfig = cloneDeep(data);
      }));
  }
}
